<template>
  <div class="left-2-up">
    <div class="left-2-1">{{title}}</div>
    <div class="left-2-2">
      <div
        v-for="(item, index) in checkCountryList"
        :key="item.item_bn"
        class="clear-item"
        @click="clearItemFunc('country', index)"
      >
        游玩国家 | {{item.item_nm}}
        <i class="el-icon-close"></i>
      </div>
      <div
        v-for="(item, index) in checkDaysList"
        :key="item.days_id"
        class="clear-item"
        @click="clearItemFunc('days', index)"
      >
        行程天数 | {{item.days_nm}}
        <i class="el-icon-close"></i>
      </div>
      <div
        v-for="(item, index) in checkStartCityList"
        :key="item.city_id"
        class="clear-item"
        @click="clearItemFunc('startCity', index)"
      >
        从哪出发 | {{item.city_nm}}
        <i class="el-icon-close"></i>
      </div>
      <div
        v-for="(item, index) in checkEndCityList"
        :key="item.city_id"
        class="clear-item"
        @click="clearItemFunc('endCity', index)"
      >
        结束城市 | {{item.city_nm}}
        <i class="el-icon-close"></i>
      </div>
    </div>
    <div class="left-2-3">
      <span v-if="isClear" class="clear" @click="clearFunc()">清除选项</span>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String
      },
      checkCountryList: {
        type: Array,
        required: true,
        default: () => []
      },
      checkDaysList: {
        type: Array,
        required: true,
        default: () => []
      },
      checkStartCityList: {
        type: Array,
        required: true,
        default: () => []
      },
      checkEndCityList: {
        type: Array,
        required: true,
        default: () => []
      },
      isClear: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
      };
    },
    computed: {
      selfCheckCountryList() {
        return this.checkCountryList;
      },
      selfCheckDaysList() {
        return this.checkDaysList;
      },
      selfCheckStartCityList() {
        return this.checkStartCityList;
      },
      selfCheckEndCityList() {
        return this.checkEndCityList;
      }
    },
    methods: {
      clearItemFunc(types, index) {
        if (types === 'country') {
          this.selfCheckCountryList.splice(index, 1);
          this.$emit('update:check-country-list', this.selfCheckCountryList);
        } else if (types === 'days') {
          this.selfCheckDaysList.splice(index, 1);
          this.$emit('update:check-days-list', this.selfCheckDaysList);
        } else if (types === 'startCity') {
          this.selfCheckStartCityList.splice(index, 1);
          this.$emit('update:check-start-city-list', this.selfCheckStartCityList);
        } else if (types === 'endCity') {
          this.selfCheckEndCityList.splice(index, 1);
          this.$emit('update:check-end-city-list', this.selfCheckEndCityList);
        }
        this.$emit("clear-data");
      },
      clearFunc() {
        this.$emit('update:check-country-list', []);
        this.$emit('update:check-days-list', []);
        this.$emit('update:check-start-city-list', []);
        this.$emit('update:check-end-city-list', []);
        this.selfCheckCountryList.splice(0, this.selfCheckCountryList.length);
        this.selfCheckDaysList.splice(0, this.selfCheckDaysList.length);
        this.selfCheckStartCityList.splice(0, this.selfCheckStartCityList.length);
        this.selfCheckEndCityList.splice(0, this.selfCheckEndCityList.length);
        this.$emit("clear-data");
      }
    }
  };
</script>

<style lang="scss" scoped>
.left-2-up {
  padding: 20px;
  display: flex;
  border-bottom: 2px solid #f5f5f5;

  .left-2-1 {
    width: 100px;
  }
  .left-2-2 {
    flex: 1;
    .clear-item {
      display: inline-block;
      vertical-align: middle;
      padding: 10px 8px;
      background: rgba(111, 15, 149, 1);
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 16px;
      margin-right: 10px;
      margin-bottom: 10px;
      i {
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
  .left-2-3 {
    padding-top: 7px;
    width: 80px;
    text-align: right;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(61, 126, 255, 1);
    .clear {
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>
