<template>
  <div
    class="left-2-up"
    style="overflow: hidden"
    :style="{ height: selfShowMore ? 'auto' : showHeight + 'px' }"
  >
    <div class="left-2-1">{{ title }}</div>
    <div class="left-2-2">
      <el-checkbox-group v-model="selfCheckList" @change="changeList">
        <el-checkbox :label="item" v-for="item in list" :key="item[id]">{{
          item[label]
        }}</el-checkbox>
      </el-checkbox-group>
    </div>
    <div class="left-2-3">
      <span v-if="selfShowMore" class="show-more" @click="isShowMoreFunc()">
        收起
        <i class="el-icon-arrow-up"></i>
      </span>
      <span v-else class="show-more" @click="isShowMoreFunc()">
        更多
        <i class="el-icon-arrow-down"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    checkList: {
      type: Array,
      required: true,
      default: () => [],
    },
    list: {
      type: Array,
      required: true,
      default: () => [],
    },
    showHeight: {
      type: Number,
      default: 62,
    },
    showMore: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selfCheckList(newVal) {
      this.$emit('update:check-list', newVal);
    },
    checkList(val) {
      this.selfCheckList = val;
    },
    showMore(val) {
      this.selfShowMore = val;
    },
  },
  data() {
    return {
      selfShowMore: false,
      selfCheckList: [],
    };
  },
  methods: {
    isShowMoreFunc() {
      this.selfShowMore = !this.selfShowMore;
      this.$emit('update:showMore', this.selfShowMore);
    },
    changeList() {
      this.$emit('change-list');
    },
  },
};
</script>

<style lang="scss" scoped>
.left-2-up {
  padding: 20px 20px 0 20px;
  display: flex;
  border-bottom: 2px solid #f5f5f5;

  .left-2-1 {
    width: 100px;
  }
  .left-2-2 {
    flex: 1;
    :deep(.el-checkbox) {
      margin-top: 3px;
      margin-bottom: 22px;
    }
    :deep(.el-checkbox__inner) {
      width: 17px;
      height: 17px;
      border: 1px solid rgba(153, 153, 153, 1);
      border-radius: 4px;
      &::after {
        border: 2px solid #fff;
        border-left: 0;
        height: 8px;
        left: 5px;
        border-top: 0;
      }
    }
    :deep(.el-checkbox__label) {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 16px;
    }
  }
  .left-2-3 {
    width: 80px;
    text-align: right;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(61, 126, 255, 1);
    .show-more {
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>