<template>
  <div class="body-main">
    <div class="main">
      <bread-crumb :list="breadCrumbList"></bread-crumb>
      <div class="main-1">
        <div class="left">
          <div class="left-top">
            <div class="left-1">全部（{{ totalPage }}）</div>
            <div class="left-2">
              <show-more
                title="游玩国家"
                label="item_nm"
                id="item_bn"
                :show-more.sync="countryShowMore"
                :list="countryList"
                :check-list.sync="checkCountryList"
                @change-list="initSearchOption"
              />
              <show-more
                title="行程天数"
                label="days_nm"
                id="days_id"
                :show-more.sync="daysShowMore"
                :list="daysList"
                :check-list.sync="checkDaysList"
                @change-list="routeByConditionFunc"
              />
              <show-more
                title="从哪出发"
                label="city_nm"
                id="city_id"
                :show-more.sync="startCityShowMore"
                :list="startCityList"
                :check-list.sync="checkStartCityList"
                @change-list="routeByConditionFunc"
              />
              <show-more
                title="结束城市"
                label="city_nm"
                id="city_id"
                :show-more.sync="endCityShowMore"
                :list="endCityList"
                :check-list.sync="checkEndCityList"
                @change-list="routeByConditionFunc"
              />
              <clear-item
                title="您已选择"
                :is-clear.sync="isClear"
                :check-country-list.sync="checkCountryList"
                :check-days-list.sync="checkDaysList"
                :check-start-city-list.sync="checkStartCityList"
                :check-end-city-list.sync="checkEndCityList"
                @clear-data="routeByConditionFunc"
              />
            </div>
          </div>
          <div class="left-md">
            <div class="left-md-q">
              <el-tabs v-model="activeTab" @tab-click="handleClick">
                <el-tab-pane label="热门" name="order_hot">
                  <span slot="label" v-if="activeTab === 'order_hot'">
                    热门
                    <i class="el-icon-bottom"></i>
                  </span>
                </el-tab-pane>
                <el-tab-pane label="满意度" name="order_good">
                  <span slot="label" v-if="activeTab === 'order_good'">
                    满意度
                    <i class="el-icon-bottom"></i>
                  </span>
                </el-tab-pane>
                <el-tab-pane label="价格降序" name="order_price_down">
                  <span slot="label" v-if="activeTab === 'order_price_down'">
                    价格降序
                    <i class="el-icon-bottom"></i>
                  </span>
                </el-tab-pane>
                <el-tab-pane label="价格升序" name="order_price_up">
                  <span slot="label" v-if="activeTab === 'order_price_up'">
                    价格升序
                    <i class="el-icon-top"></i>
                  </span>
                </el-tab-pane>
              </el-tabs>
              <el-date-picker
                class="start-date"
                v-model="startDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="最早出发日"
                :picker-options="pickerOptions"
                @change="routeByConditionFunc"
              >
                <i slot="prefix" class="el-input__icon el-icon-time"></i>
              </el-date-picker>
              <el-date-picker
                class="end-date"
                v-model="endDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="最晚出发日"
                :picker-options="pickerOptions"
                @change="routeByConditionFunc"
              >
                <i slot="prefix" class="el-input__icon el-icon-time"></i>
              </el-date-picker>
            </div>
            <div class="left-md-w">
              <el-checkbox v-model="join_bus" @change="routeByConditionFunc"
                >当地上车参团</el-checkbox
              >
              <el-checkbox v-model="join_airport" @change="routeByConditionFunc"
                >接机参团</el-checkbox
              >
              <el-checkbox v-model="confirmation" @change="routeByConditionFunc"
                >订即确认</el-checkbox
              >
              <el-checkbox v-model="only_sale" @change="routeByConditionFunc"
                >只看可售</el-checkbox
              >
            </div>
          </div>
          <route-list :list="resultList">
            <pagination
              background
              @current-change="handleCurrentChange"
              :current-page.sync="searchCondition.page"
              :page-size="searchCondition.per_page"
              layout="prev, pager, next, jumper"
              :total="totalPage"
            ></pagination>
          </route-list>
        </div>
        <div class="right">
          <div class="box-4">
            <router-link
              :to="{
                path: '/travel-route/detail',
                query: { route_bn: item.route_bn },
              }"
              class="box-4-item"
              v-for="(item, index) in adData"
              :key="index"
            >
              <img :src="item.ad_img" alt class="box-4-item-icon" />
              <div class="box-4-item-desc"  :title="item.route_title">{{ item.route_title }}</div>
              <div class="box-4-item-price">
                <span class="price">{{ item.route_price }}</span
                >起
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb';
import pagination from '@/components/pagination';
import ShowMore from './components/ShowMore';
import ClearItem from './components/ClearItem';
import RouteList from '@/components/RouteList';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import {
  itemCountry,
  itemDays,
  itemCity,
  itemRouteCity,
  routeByCondition,
  routeTagDetailSearch,
} from '@/api/index';
export default {
  name: 'travelRouteList',
  components: {
    BreadCrumb,
    pagination,
    ShowMore,
    ClearItem,
    RouteList,
  },
  data() {
    return {
      breadCrumbList: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            },
          },
          {
            text: '明天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit('pick', date);
            },
          },
          {
            text: '一周后',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            },
          },
        ],
      },
      countryList: [],
      checkCountryList: [],
      countryShowMore: false,
      daysList: [],
      checkDaysList: [],
      daysShowMore: false,
      startCityList: [],
      checkStartCityList: [],
      startCityShowMore: false,
      endCityList: [],
      checkEndCityList: [],
      endCityShowMore: false,
      searchCondition: {
        page: 1,
        per_page: 5,
        region_bn: '',
        country_bn: [],
        start_city_bn: [],
        end_city_bn: [],
        days_num: [],
        start_date: '',
        end_date: '',
        join_bus: '',
        join_airport: '',
        confirmation: '',
        only_sale: '',
        order_hot: '',
        order_good: '',
        order_price: '',
      },
      totalRoutes: 0,
      totalPage: 0,
      resultList: [],
      activeTab: '',
      startDate: '',
      endDate: '',
      join_bus: false,
      join_airport: false,
      confirmation: false,
      only_sale: false,
      adData: [],
      route_type: '1',
      listInitFlag: 0
    };
  },
  created() {
    this.startDate = this.route.routeObj.start_date;
    this.endDate = this.route.routeObj.end_date;
    if (this.route.routeObj.route_type) {
      this.route_type = '2';
    }
    if (this.route.routeObj && this.route.routeObj.region_id) {
      this.searchCondition.region_bn = this.route.routeObj.region_id;
    }
    this.itemCountryFunc();
    this.itemDaysFunc();
    this.itemCityFunc();
    // this.routeByConditionFunc();
    this.getAdData();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    currency() {
      return this.$store.state.home.currency.currency_id;
    },
    isClear() {
      return !![
        ...this.checkCountryList,
        ...this.checkDaysList,
        ...this.checkStartCityList,
        ...this.checkEndCityList,
      ].length;
    },
    ...mapState(['route']),
  },
  watch: {
    currency: {
      handler(val) {
        this.searchCondition.currency = Number(val);
        if (this.listInitFlag === 1) {
          this.routeByConditionFunc();
        }
      },
      immediate: true,
    },
    route: {
      handler(newVal) {
        this.breadCrumbList = [
          {
            name: newVal.routeObj.region_nm,
            path: '/travel-route/list',
            query: '',
          },
        ];
        if (newVal.routeObj.country_nm) {
          this.breadCrumbList.push({
            name: newVal.routeObj.country_nm,
            path: '',
            query: '',
          });
        }
        this.checkCountryList =
          this.countryList.filter(
            (item) => item.item_bn === newVal.routeObj.country_bn
          ) || [];
        this.breadCrumbList.name = newVal.routeObj.region_nm;
        // this.routeByConditionFunc();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['changSetRouteObj']),
    // 初始化查询条件
    initSearchOption() {
      this.searchCondition.page = 1;
      this.searchCondition.per_page = 5;
      this.routeByConditionFunc()
    },
    // 子页-右侧广告
    getAdData() {
      routeTagDetailSearch({ tagid: 'tag_type_101' }).then((res) => {
        this.adData = res.data;
      });
    },
    // 国家列表
    itemCountryFunc() {
      itemCountry({
        region_bn: this.route.routeObj.region_id,
      }).then((response) => {
        this.countryList = response.data;
        this.checkCountryList =
          this.countryList.filter(
            (item) => item.item_bn === this.route.routeObj.country_bn
          ) || [];
        this.routeByConditionFunc();
      });
    },
    // 天数列表
    itemDaysFunc() {
      itemDays().then((response) => {
        this.daysList = response.data;
      });
    },
    // 开始城市
    // 结束城市
    itemCityFunc() {
      //      itemCity().then((response) => {
      itemRouteCity().then((response) => {
        this.startCityList = response.data;
        this.endCityList = response.data;
        this.checkStartCityList =
          this.startCityList.filter(
            (item) => item.id === this.route.routeObj.start_city_bn
          ) || [];
        this.checkEndCityList =
          this.endCityList.filter(
            (item) => item.id === this.route.routeObj.end_city_bn
          ) || [];
      });
    },
    // 线路检索
    routeByConditionFunc() {
      this.resultList = [];
      // this.searchCondition.region_bn = '';
      this.searchCondition.country_bn = this.checkCountryList.map(
        (item) => item.item_bn
      );
      this.searchCondition.days_num = this.checkDaysList.map(
        (item) => item.days_id
      );
      this.searchCondition.start_city_bn = this.checkStartCityList.map(
        (item) => item.city_bn
      );
      this.searchCondition.end_city_bn = this.checkEndCityList.map(
        (item) => item.city_bn
      );
      this.searchCondition.start_date = this.startDate
        ? moment(this.startDate).format('YYYY-MM-DD') + 'T16:00:00.000000Z'
        : '';
      this.searchCondition.end_date = this.endDate
        ? moment(this.endDate).format('YYYY-MM-DD') + 'T16:00:00.000000Z'
        : '';
      this.searchCondition.join_bus = this.join_bus ? 1 : 0;
      this.searchCondition.join_airport = this.join_airport ? 1 : 0;
      this.searchCondition.confirmation = this.confirmation ? 1 : 0;
      this.searchCondition.only_sale = this.only_sale ? 1 : 0;
      this.searchCondition.route_type = this.route_type;
      routeByCondition(this.searchCondition).then((response) => {
        this.resultList = response.data;
        this.totalPage = response.total_nums;
        this.listInitFlag = 1;
      });
    },
    handleClick(tab) {
      if (tab.name === 'order_hot') {
        this.searchCondition.order_hot = 1;
        this.searchCondition.order_good = 0;
        this.searchCondition.order_price = 0;
      } else if (tab.name === 'order_good') {
        this.searchCondition.order_hot = 0;
        this.searchCondition.order_good = 1;
        this.searchCondition.order_price = 0;
      } else if (tab.name === 'order_price_down') {
        this.searchCondition.order_hot = 0;
        this.searchCondition.order_good = 0;
        this.searchCondition.order_price = 2;
      } else if (tab.name === 'order_price_up') {
        this.searchCondition.order_hot = 0;
        this.searchCondition.order_good = 0;
        this.searchCondition.order_price = 1;
      }
      this.routeByConditionFunc();
    },
    handleCurrentChange(val) {
      this.searchCondition.page = val;
      this.routeByConditionFunc();
    },
  },
};
</script>

<style lang="scss" scoped>
.body-main {
  width: 100%;
  display: flex;
  background: rgba(245, 245, 245, 1);
  justify-content: center;
  .main {
    width: 1200px;
    .main-1 {
      display: flex;
      .left {
        width: 930px;
        margin-right: 15px;
        .left-1 {
          display: inline-block;
          background: rgba(111, 15, 149, 1);
          border-radius: 12px 12px 0px 0px;
          padding: 10px 20px;
          color: #ffffff;
        }
        .left-2 {
          background-color: #ffffff;
        }
        .left-md {
          width: 930px;
          height: 121px;
          background: rgba(255, 255, 255, 1);
          margin: 20px 0;
          &-q {
            padding: 0px 55px;
            position: relative;
            border-bottom: 1px solid rgba(245, 245, 245, 1);
            :deep(.el-tabs__item) {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: rgba(51, 51, 51, 1);
            }
            :deep(.el-tabs__item.is-active) {
              color: #3d7eff;
            }
            :deep(.el-tabs--top) {
              line-height: 60px;
            }
            :deep(.el-tabs__header) {
              margin-bottom: 0;
            }
            :deep(.el-tabs__nav-wrap::after) {
              display: none;
            }
            .start-date {
              position: absolute;
              width: 130px;
              top: 17px;
              left: 500px;
              :deep(.el-input__inner) {
                height: 30px;
                line-height: 30px;
                padding-right: 10px;
              }
              :deep(.el-input__icon) {
                line-height: 30px;
              }
            }
            .end-date {
              position: absolute;
              width: 130px;
              height: 30px;
              top: 17px;
              left: 650px;
              :deep(.el-input__inner) {
                height: 30px;
                line-height: 30px;
                padding-right: 10px;
              }
              :deep(.el-input__icon) {
                line-height: 30px;
              }
            }
          }
          &-w {
            padding: 20px 33px;
            :deep(.el-checkbox__inner) {
              width: 17px;
              height: 17px;
              border: 1px solid rgba(153, 153, 153, 1);
              border-radius: 4px;
              &::after {
                border: 2px solid #fff;
                border-left: 0;
                height: 8px;
                left: 5px;
                border-top: 0;
              }
            }
            :deep(.el-checkbox__label) {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: rgba(51, 51, 51, 1);
              line-height: 16px;
              margin-right: 10px;
            }
          }
        }
      }
      .right {
        width: 254px;
        margin-top: 41px;
        .box-3 {
          width: 100%;
          height: 170px;
          margin-bottom: 20px;
        }
        .box-4 {
          background: rgba(255, 255, 255, 1);
          padding: 10px;
          &-title {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
            line-height: 24px;
            margin: 2px 0 10px;
          }
          &-item {
            display: inline-block;
            border-bottom: 2px solid rgba(245, 245, 245, 1);
            margin-bottom: 14px;
            &:last-child {
              border: none;
            }
            &-icon {
              height: 135px;
              width: 100%;
              margin-bottom: 10px;
            }
            &-desc {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: rgba(102, 102, 102, 1);
              line-height: 18px;
              margin-bottom: 15px;
              height: 165px;
              overflow:hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 9; // 多行在这里修改数字即可，这里显示9行
              overflow:hidden;
              -webkit-box-orient: vertical;
            }
            &-price {
              .price {
                color: rgba(255, 0, 0, 1);
              }
              padding-bottom: 15px;

              text-align: right;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
</style>